/* src/index.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

header {
  background: #333;
  color: #fff;
  padding: 0px 0;
  text-align: center;
}

nav a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
}

footer {
  text-align: center;
  padding: 10px 0;
  background: #333;
  color: #fff;
  position: relative;
  bottom: 0;
  width: 100%;
}

h2 {
  color: #f1eaea;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
